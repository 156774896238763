// 
// structure-rtl.scss
//

// topbar.scss

.navbar-header{
    padding: 0 0 0 calc(#{$grid-gutter-width} / 2);
}


.main-content{
    margin-left: 0px;
    margin-right: $sidebar-width;
}

#page-topbar, .footer{
    left: 0px;
    right: $sidebar-width;
}

#page-topbar {
    @media (max-width: 991.98px) {
        right: 0;
    }
}

.app-search{
    .form-control{
        padding-right: 40px;
        padding-left: 20px;
    }

    span{
        right: 13px;
        left: auto;
    }
}

.noti-icon{
    .badge{
        right: auto;
        left: 5px;
    }
}


// Sidebar

.vertical-menu{
    .vertical-menu-btn{
        right: auto;
        left: 0;
    }
}

#sidebar-menu {
    .has-arrow{
        &:after{
            float: left;
        }
    }
    ul {
        li {
            ul.sub-menu{
                li {
                    a{
                        padding: .4rem 3.5rem .4rem 1.5rem;
                    }
                }
            }
        }
    }
}

body:not(.vertical-collpsed){
    .app-search{
        margin-left: 0px;
        margin-right: 10px;
    }
}

.vertical-collpsed {

    .main-content{
        margin-left: 0px;
        margin-right: $sidebar-collapsed-width;
    }

    #page-topbar, .footer{
        left: 0px !important;
        right: $sidebar-collapsed-width;
    }
    
    .vertical-menu {
        #sidebar-menu{
            >ul{
                >li{
                    >a {

                        span{
                            padding-right: 25px;
                            padding-left: 0;
                        }
                    }

                    &:hover{
                        >ul{
                            left: 0;
                            right: 70px;
                        }
                    }
                }

                ul li:hover>ul{
                    left: auto;
                    right: 190px;
                }
            }
        }
    }
}

@media (max-width: 992px) {

    .main-content {
        margin-right: 0 !important;
    }
    .footer {
        right: 0;
    }
}

// right-sidebar.scss
//

.right-bar {
    float: left !important;
    left: -($rightbar-width + 10px);
    right: auto;

    .user-box {
        .user-img {
            .user-edit {
                right: 0;
                left: -5px;
            }
        }
    }
}

.right-bar-enabled {
    .right-bar {
        left: 0;
        right: auto;
    }
}


// Compact sidebar


body[data-sidebar-size=small] {

    #page-topbar, .footer{
        left: 0;
        right: $sidebar-width-sm;
        @media (max-width: 991.98px){
            right: 0;
        }
    }

    #sidebar-menu {
        ul {
            li {
                ul.sub-menu {
                    li a{
                        padding-right: 1.5rem;
                    }
                }
            }
        }
    }

    &.vertical-collpsed {
        .vertical-menu {
            #sidebar-menu{
                text-align: right;
            }
        }

        .main-content {
            margin-left: 0;
            margin-right: $sidebar-collapsed-width;
        }

 
        #page-topbar, .footer {
            left: 0;
            right: $sidebar-collapsed-width;
        }
    }

    .main-content {
        margin-right: $sidebar-width-sm;
        margin-left: 0;
    }
}

// Horizontal layout

body[data-layout=horizontal] {
    #page-topbar{
        right: 0;
    }
    
    .main-content{
        margin-right: 0!important;
    }

    .footer {
        right: 0!important;
    }

    .navbar-brand-box{
        @media (min-width: 992px) {
            padding-left: 24px;
            padding-right: 0;
            
        }
    }

    .topnav {
        .arrow-down {
            &::after {
                right: auto;
                left: 15px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .topnav {


        .dropdown {
            .dropdown-menu{
                right: 0px;
                left: auto;
            }
        }
    }
}

.arrow-down{
    &:after{
        margin-left: 0px;
        margin-right: 10px;
    }
}

.navbar-nav {
    .dropdown-menu{
        text-align: right;
    }
}

@media (min-width: 992px){
    .topnav {
        .dropdown {
            .dropdown-menu {
                .arrow-down::after {
                    right: auto;
                    left: 15px;
                    transform: rotate(45deg) translateY(-50%);
                }

                .dropdown {
                    .dropdown-menu{
                        right: 100%;
                        left: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px){
    .topnav {
        .dropdown {
            .dropdown-menu {
                padding-left: 0px;
                padding-right: 15px;
            }
        }
    }
}
